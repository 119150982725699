<template>

  <div style="padding: 16px 16px 16px 16px;">
    <p><i class="dx-icon -nav-icon tabnav-icon fa-light fa-file-contract"></i>{{ currentUser.Translation.vueappNavMemberContracts }}</p>
  </div>

  <div class="dx-card -no-paddings" style="position: relative;">

    <!-- allow-adding="false" then this -->
    <div class="" style="position: absolute; top: 15px; right: 15px; z-index: 1;">
      <button @click="createContract" class="cx-button tiny">
        <i class="fa-light fa-file-contract"></i>
        Vertrag erstellen
      </button>
    </div>

  <DxDataGrid
    ref="myDataGrid"
    id="gridContainer"
    :data-source="customDataSource"
    :focused-row-enabled="false"
    :column-auto-width="true"
    :column-hiding-enabled="true"
    :allow-column-reordering="true"
    :show-borders="false"
  >

  <DxPaging :page-size="500" />
  <DxPager :show-page-size-selector="false" :show-info="true" />
  <DxFilterRow :visible="true" />

  <DxEditing
    :allow-updating="false"
    :allow-deleting="true"
    :allow-adding="false"
    mode="row"
  />  
  
  <DxColumn
    alignment='left' 
    data-field="Search"
    :caption="title"
    :placeholder="'Suchen'"
    :hiding-priority="99"
    cell-template="contractTemplate"

  />
  <template #contractTemplate="{ data: content }">

    <div class="grid-x align-middle">

      <div class="shrink cell">

      <!-- avatar -->
      <div class="user-thumb" style="margin: 2px 10px 2px 0;"
        v-bind:style="{ 'background-image': 'url(' + content.data.AboPicthumb + '?v=' + timestamp + ')' }" >
      </div>
      <!-- /avatar -->

      </div><!-- /shrink cell main -->

      <div class="auto cell">

        <div class="grid-x align-middle">

          <div class="shrink cell">
            <!-- row 1 -->
            <div class="fixedwidth-listitem">
              <p class="ck-overflow-ellipsis strong small">
                <span v-if="content.data.CustomerSubject">
                  {{ content.data.CustomerSubject }}
                </span>
                <span v-else class="alert-color">
                  <i class="fa-light fa-triangle-exclamation"></i> Kunde
                </span>
              </p>

              <p class="ck-overflow-ellipsis small">
                VG-{{ content.data.id }}
              </p>

            </div><!-- /fixedwidth-listitem -->
          </div><!-- /shrink cell -->

          <div class="shrink cell">
            <!-- row 2 -->
            <div class="fixedwidth-listitem">
              <p class="ck-overflow-ellipsis strong small" :id="'titleAboSubject'+content.data.id">
                <span v-if="content.data.AboSubject">
                  {{ content.data.AboSubject }}
                </span>
                <span v-else>
                  <span class="alert-color">
                    <i class="fa-light fa-triangle-exclamation"></i> Abo
                  </span>
                </span>
              </p>

              <p class="ck-overflow-ellipsis small" :id="'titleValidDates'+content.data.id">
                <span v-if="content.data.ValidDates && content.data.DateStart && content.data.DateStop">
                  <span v-if="content.data.ValidDates.slice(0, 1)">{{ formatDate(content.data.ValidDates.slice(0, 1)) }}</span> 
                  <span v-if="content.data.ValidDates.slice(-1)"> – {{ formatDate(content.data.ValidDates.slice(-1)) }}</span>
                </span>
                <span v-else class="alert-color">
                  <i class="fa-light fa-triangle-exclamation"></i> Zeitraum
                </span>
              </p>

            </div><!-- /fixedwidth-listitem -->
          </div><!-- /shrink cell -->

          <div class="shrink cell">
            <!-- row 3 -->
            <div class="fixedwidth-listitem">
              <p class="ck-overflow-ellipsis strong small" :id="'titlePrice'+content.data.id">

                <span v-if="content.data.Price">
                  {{ content.data.Currency }} {{ formatPrice(content.data.Price - content.data.Discount) }}
                </span>
                <span v-else class="alert-color">
                  <i class="fa-light fa-triangle-exclamation"></i> Preis
                </span>
              </p>

              <p class="ck-overflow-ellipsis small" :id="'titleRates'+content.data.id">
                <span v-if="content.data.Rates">
                {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
                </span>
                <span v-else class="alert-color">
                  <i class="fa-light fa-triangle-exclamation"></i> Raten
                </span>
              </p>

            </div><!-- /fixedwidth-listitem -->
          </div><!-- /shrink cell -->

          <div class="shrink cell">
            <!-- row 5 -->
            <div class="fixedwidth-listitem">
              <p class="ck-overflow-ellipsis strong small">
                <span v-if="content.data.GetInvoiceid">
                  RE-{{ content.data.GetInvoiceid }}
                </span>
                <span v-else class="alert-color">
                  <i class="fa-light fa-triangle-exclamation"></i> Rechnung
                </span>
              </p>

              <p class="ck-overflow-ellipsis small">
                <span v-if="content.data.SignaturePng" class="success-color">
                  <i class="fa-light fa-circle-check"></i> Unterschrift
                </span>
                <span v-else class="alert-color">
                  <i class="fa-light fa-triangle-exclamation"></i> Unterschrift
                </span>
              </p>
            </div><!-- /fixedwidth-listitem -->
          </div><!-- /shrink cell -->

          <div class="shrink cell">
            <!-- row 6 -->
            <div class="fixedwidth-listitem">
              <p v-if="diffDays(content.data.DateStop) < 30" class="ck-overflow-ellipsis small strong alert-color">
                <i class="fa-light fa-triangle-exclamation"></i> {{ diffDays(content.data.DateStop) }} Tage
              </p>
              <p v-if="diffDays(content.data.DateStop) < 60 && diffDays(content.data.DateStop) >= 30" class="ck-overflow-ellipsis small strong warning-color">
                <i class="fa-light fa-triangle-exclamation"></i> {{ diffDays(content.data.DateStop) }} Tage
              </p>
              <p v-if="diffDays(content.data.DateStop) >= 60" class="ck-overflow-ellipsis small strong success-color">
                <i class="fa-light fa-circle-check"></i> {{ diffDays(content.data.DateStop) }} Tage
              </p>

              <div class="reminder-area" :class="{ hide: diffDays(content.data.DateStop) >= 60 }">

                <p style="font-size: 11px; line-height: 16px;">
                  <a 
                    :href="'#/renew-abo/'+content.data.Customerid+'-'+content.data.id" 
                    target="_blank" 
                    class="-hide -cx-button -tiny">
                    <i class="fa-light fa-share"></i> 
                    Reminder ansehen
                  </a>
                </p>

                <template v-if="content.data.SentDate">
                  <p style="font-size: 11px; line-height: 16px;" >
                    <i class="fa-light fa-envelope"></i>
                    gesendet: {{formatDate( content.data.SentDate) }}
                  </p>
                </template>
                <template v-else>
                  <p v-if="!content.data.RenewDate" style="font-size: 11px; line-height: 16px;" >
                    <a 
                    @click="sendReminder(content.data.Customerid,content.data.id,content.data.CustomerEmail)" 
                    >
                    <i class="fa-light fa-envelope"></i>
                    <span :id="'reminderdate'+content.data.id"> Reminder senden</span>
                  </a>
                  </p>
                </template>

                <template v-if="content.data.RenewDate">
                  <p style="font-size: 11px; line-height: 16px;">
                    <i class="fa-solid fa-arrow-right-from-line"></i>
                    verlängert: {{formatDate( content.data.RenewDate) }}
                  </p>
                </template>
                <template v-else>
                  <p style="font-size: 11px; line-height: 16px;">
                    <a 
                    @click="dupContract(
                      content.data.Employeeid,
                      content.data.LocationId,
                      content.data.Aboid,
                      content.data.Price,
                      content.data.Discount,
                      content.data.Rates,
                      content.data.Interval,
                      content.data.DateStart,
                      content.data.DateStop,
                      content.data.SignaturePng,
                      new Date(),
                      content.data.id,
                    )" 
                  >
                  <i class="fa-solid fa-arrow-right-from-line"></i>
                    Vertrag verlängern
                  </a>
                </p>
              </template>

              </div><!-- /reminder-area -->
              
            </div><!-- /fixedwidth-listitem -->
          </div><!-- /shrink cell -->


        </div><!-- /grid-x sub-->

        
      </div><!-- /auto cell main -->


    </div><!-- /grid-x main -->


    <div class="grid-x -grid-margin-x align-middle ck-itemrow hide">

      <div class="shrink cell">
        <!-- avatar -->
        <div class="user-thumb" style="margin-right: 15px;"
          v-bind:style="{ 'background-image': 'url(' + content.data.AboPicthumb + '?v=' + timestamp + ')' }" >
        </div>
        <!-- /avatar -->
      </div><!-- /shrink cell -->

      <div class="auto cell">
        <p class="ck-overflow-ellipsis strong">

          <span v-if="content.data.AboSubject">
            VG-{{ content.data.id}} | {{ content.data.AboSubject }}
          </span>
          <span v-else class="alert-color">
            <i class="fa-light fa-triangle-exclamation"></i> Abo
          </span>

        </p>
        <p class="ck-overflow-ellipsis small -hide">          

          <span v-if="content.data.DateStart && content.data.DateStop">
            {{ formatDate(content.data.DateStart) }} – {{ formatDate(content.data.DateStop) }}
          </span>
          <span v-else class="alert-color">
            <i class="fa-light fa-triangle-exclamation"></i> Zeitraum
          </span>
          | 
          <span v-if="content.data.Price">
            {{ content.data.Currency }} {{ formatPrice(content.data.Price - content.data.Discount) }}
          </span>
          <span v-else class="alert-color">
            <i class="fa-light fa-triangle-exclamation"></i> Preis
          </span>
          | 
          <span v-if="content.data.Rates && content.data.Price">
            {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
          </span>
          <span v-else class="alert-color">
            <i class="fa-light fa-triangle-exclamation"></i> Raten
          </span>

        </p>
      </div>
    </div>
  </template>

  <DxMasterDetail
    :enabled="true"
    template="masterDetailTemplate"
  />
  <template #masterDetailTemplate="{ data: content }">
    <DetailTemplate
      :template-data="content"
    />
  </template>

</DxDataGrid>

</div><!-- dx-card -->

</template>

<script>
 
import {
  DxDataGrid, 
  DxFilterRow, 
  DxColumn, 
  DxEditing, 
  DxPager, 
  DxPaging, 
  DxMasterDetail,
  //DxButton,
  //DxLookup, 
} from 'devextreme-vue/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';

import DetailTemplate from '../parts/contract-item-detail-master.vue';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

let currentUser;
let customerId;

let paramEmployeeId;
let paramLocationId;
let paramAboId;
let paramPrice;
let paramDiscount;
let paramRates;
let paramInterval;
let paramDateStart;
let paramDateStop;
let paramSignature;
let paramDateRenew;
let paramContractId;


auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

const customDataSource = new CustomStore({
  key: 'id',
  load: () => {
    return fetch(apihost+'/de/vue/contract/list/?Customerid='+customerId)
    .then(handleErrors)
    .then(response => response.text())
    .then(data => {
    //console.log(`response text`, text)
    return JSON.parse(data)
    })
    .catch(() => { throw 'Network error' });
  },
  insert: () => {

    // console.log(paramEmployeeId);
    // console.log(paramLocationId);
    // console.log(paramAboId);
    // console.log(paramPrice);
    // console.log(paramDiscount);
    // console.log(paramRates);
    // console.log(paramInterval);
    // console.log(paramDateStart);
    // console.log(paramDateStop);
    // console.log(paramSignature);
    // console.log(paramDateRenew);    
    // console.log(paramContractId);    
    
    const key = '0';
    //const objString = '?' + new URLSearchParams(values).toString();
    let objString = '?CustomerId=' + customerId;

    if(paramEmployeeId){
      objString = objString + '&EmployeeId=' + paramEmployeeId;
    } else {
      objString = objString + '&EmployeeId=';
    }

    if(paramLocationId){
      objString = objString + '&LocationId=' + paramLocationId;
    } else {
      objString = objString + '&LocationId=';
    }

    if(paramAboId){
      objString = objString + '&AboId=' + paramAboId;
    } else {
      objString = objString + '&AboId=';
    }

    if(paramPrice){
      objString = objString + '&Price=' + paramPrice;
    } else {
      objString = objString + '&Price=';
    }

    if(paramDiscount){
      objString = objString + '&Discount=' + paramDiscount;
    } else {
      objString = objString + '&Discount=';
    }

    if(paramRates){
      objString = objString + '&Rates=' + paramRates;
    } else {
      objString = objString + '&Rates=';
    }

    if(paramInterval){
      objString = objString + '&Interval=' + paramInterval;
    } else {
      objString = objString + '&Interval=';
    }

    if(paramDateStart){
      objString = objString + '&DateStart=' + paramDateStart;
    } else {
      objString = objString + '&DateStart=';
    }

    if(paramDateStop){
      objString = objString + '&DateStop=' + paramDateStop;
    } else {
      objString = objString + '&DateStop=';
    }

    if(paramSignature){
      objString = objString + '&Signature=' + paramSignature;
    } else {
      objString = objString + '&Signature=';
    }

    if(paramDateRenew){
      objString = objString + '&DateRenew=' + paramDateRenew;
    } else {
      objString = objString + '&DateRenew=';
    }

    if(paramContractId){
      objString = objString + '&ContractId=' + paramContractId;
    } else {
      objString = objString + '&ContractId=';
    }

    //case renew
    objString = objString + '&CaseRenew=renew';


    console.log(objString);

    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/insert'+objString, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
    },
    remove: (key) => {
      //console.log(key);
      return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/remove', {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
      // console.log(key);
      // console.log(values);
      const objString = '?' + new URLSearchParams(values).toString();
      return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/save'+objString, {
        // method: 'GET',
      })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
    }

  });

  // console.log(customDataSource)


export default {
  name: 'customer-tasklist',

  props: {
    customerData: Object,
  },

  components: {
    DxDataGrid,
    DxFilterRow,
    DxColumn,
    DxEditing,
    DxPager,
    DxPaging,
    DxMasterDetail,
    //DxButton,
    //DxLookup,

    DetailTemplate,

  },

  mounted() {

    this.timestamp = this.printTimestamp();

    // console.log(this.customerData.id);
    // customerId = '1189';
    if(this.customerData){
      customerId = this.customerData.id;
    }
  },

  data() {
    return {
      apihost,
      
      title: currentUser.Translation.vueappNavMemberContracts,
      msg: 'Tasks (part)',
      // statuses,
      // priorities,
      currentUser,
      customDataSource,

      paramEmployeeId,
      paramLocationId,
      paramAboId,
      paramPrice,
      paramDiscount,
      paramRates,
      paramInterval,
      paramDateStart,
      paramDateStop,
      paramSignature,
      paramDateRenew,
      paramContractId,

      validationRules: {
        status: [
          { type: 'required', message: 'Status is required.' },
        ],
        priority: [
          { type: 'required', message: 'Priority is required.' },
        ],
      },
      statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
      priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },

    };
  },
  methods: {

    diffDays(dateStop) {
      const now = new Date();
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const stopDate = new Date(dateStop);
      //const diffDays = Math.round(Math.abs((now - stopDate) / oneDay));
      const diffDays = parseInt((now - stopDate) / oneDay * -1);
      return diffDays;
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      //return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
      return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
    },

    formatPrice(value) {
      value = parseFloat(value);
      //auf 5 Rappen runden
      value = Math.round(value * 20) / 20;
      //zwei Stellen anzeigen
      value = value.toFixed(2);
      return value;
    },

    printTimestamp: function () {
      return Date.now();
    },

    createContract() {
      this.$refs['myDataGrid'].instance.addRow();
      const savebutton = document.getElementsByClassName("dx-link-save")[0];
      if(savebutton){
        savebutton.click();
      }
      
      notify({
        message: currentUser.Translation.vueappContractFormSubmitSuccess,
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);

    },

    dupContract(EmployeeId,LocationId,Aboid,Price,Discount,Rates,Interval,DateStart,DateStop,SignaturePng,DateRenew,ContractId) {

      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const startDate = new Date(DateStart);
      const stopDate = new Date(DateStop);
      const diffDays = Math.round(Math.abs((startDate - stopDate) / oneDay));
      const inOneDay = new Date(new Date(stopDate).setDate(stopDate.getDate() + 1));
      const inDiffDays = new Date(new Date(stopDate).setDate(stopDate.getDate() + diffDays));
      const Signature = SignaturePng.split("/").splice(-1)[0];

      // "DateStart": "2024-01-01T00:00:00+01:00",
      // "DateStop": "2025-01-01T00:00:00+01:00",

      // console.log(DateStart);
      // console.log(DateStop);
      // console.log(diffDays);
      // console.log(inOneDay.toISOString());
      // console.log(inDiffDays.toISOString());
      // console.log(Signature);

      paramEmployeeId = EmployeeId;
      paramLocationId = LocationId;
      paramAboId = Aboid;
      paramPrice = Price;
      paramDiscount = Discount;
      paramRates = Rates;
      paramInterval = Interval;
      paramDateStart = inOneDay.toISOString();
      paramDateStop = inDiffDays.toISOString();
      paramSignature = Signature;
      paramDateRenew = DateRenew.toISOString();
      paramContractId = ContractId;


      this.$refs['myDataGrid'].instance.addRow();
      const savebutton = document.getElementsByClassName("dx-link-save")[0];
      if(savebutton){
        savebutton.click();
      }
      
      notify({
        message: currentUser.Translation.vueappContractFormSubmitSuccess,
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);

    },

    sendReminder(CustomerId, ContractId, CustomerEmail){

      const reminderdateHtml = document.getElementById("reminderdate"+ContractId);
      const today = new Date();

      if(reminderdateHtml){
        reminderdateHtml.innerHTML = ' gesendet: '+ today.toLocaleDateString();
      }

      fetch(apihost+'/de/mail/reminder/'+CustomerId+'/'+ContractId)
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          console.log(data);
        })
      .catch(() => { throw 'Network error' });


      notify({
      message: 'Reminder wurde an '+ CustomerEmail +' versandt',
      position: {
        my: 'center top',
        at: 'center top',
      },
      }, 'success', 3000);

    },

  },

};

</script>